/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["event"] }] */

import { DatadogLoggingService } from '@edx/frontend-logging';

import {
  getRoutes,
  flattenRoutePaths,
  replaceRouteParamsInPath,
} from './src/routes';

// Extract the route paths from the list of application routes
const { rootChildRoutes } = getRoutes();
const routePaths = flattenRoutePaths(rootChildRoutes);

/**
 * @typedef {Object} Event
 * @property {Object} view - The view information within the event.
 * @property {string} [view.url] - The URL of the view.
 * @property {string} [view.name] - The name of the view.
 * @property {string} [view.referrer] - The referrer URL of the view.
 */

/**
 * Cleans and updates the view URL in the event object.
 * Updates the `event.view.url` and `event.view.name` properties with a path
 * that has dynamic parameters replaced with '?'.
 *
 * @param {Event} event - The event object containing view information.
 */
function cleanViewUrl(event) {
  if (!event.view.url) {
    return;
  }
  const viewUrl = new URL(event.view.url);
  const updatedViewPath = replaceRouteParamsInPath(viewUrl.pathname, routePaths);
  event.view.url = `${viewUrl.origin}${updatedViewPath}`;
  event.view.name = updatedViewPath;
}

/**
 * Cleans and updates the referrer URL in the event object.
 * Updates the `event.view.referrer` property with a path that has dynamic
 * parameters replaced with '?'.
 *
 * @param {Event} event - The event object containing view information.
 */
function cleanReferrerUrl(event) {
  if (!event.view.referrer) {
    return;
  }
  const referrerUrl = new URL(event.view.referrer);
  const updatedReferrerPath = replaceRouteParamsInPath(referrerUrl.pathname, routePaths);
  event.view.referrer = `${referrerUrl.origin}${updatedReferrerPath}`;
}

/**
 * Cleans the event object by updating view URLs and referrers.
 * Calls `cleanViewUrl` and `cleanReferrerUrl` to update the
 * relevant properties on the event object.
 *
 * @param {Event} event - The event object to be cleaned.
 */
function cleanEvent(event) {
  // Clean the event view url + name (note: name is derived from the url)
  cleanViewUrl(event);

  // Clean the event view referrer
  cleanReferrerUrl(event);
}

/**
 * Custom implementation of DatadogLoggingService to include additional
 * event cleaning logic before sending the event to Datadog.
 *
 * @extends DatadogLoggingService
 */
class CustomDatadogLoggingService extends DatadogLoggingService {
  beforeSend(event, context) {
    // Perform any common/shared logic for `beforeSend` defined within DatadogLoggingService.
    const baseBeforeSendResult = super.beforeSend(event, context);
    if (!baseBeforeSendResult) {
      // Base `beforeSend` logic denotes the event should be discarded; no need to do addtl checks
      return false;
    }

    // Cleans/updates the event object to mask dynamic parameters (e.g., view url + name)
    cleanEvent(event);

    // Keep the event, send to Datadog
    return true;
  }
}

const config = {
  loggingService: CustomDatadogLoggingService,
};

export default config;
